<template>
  <div>
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-card-text class="d-flex">
        <v-avatar
          rounded
          size="120"
          class="me-6"
        >
          <v-img :src="cropped"></v-img>
        </v-avatar>

        <!-- upload photo -->
        <div>
          <v-btn
            color="primary"
            class="me-3 mt-5"
            @click="$refs.refInputEl.click()"
          >
            <v-icon class="d-sm-none">
              {{ icons.mdiCloudUploadOutline }}
            </v-icon>
            <span class="d-none d-sm-block">Upload new photo</span>
          </v-btn>

          <input
            ref="refInputEl"
            type="file"
            accept=".jpeg,.png"
            :hidden="true"
            v-on:click="$refs.refInputEl.value = ''"
            v-on:change="croppie"
          />

          <v-btn
            color="error"
            outlined
            class="mt-5"
            @click="resett()"
          >
            Reset
          </v-btn>
          <p class="text-sm mt-5">
            Allowed JPG or PNG
          </p>
        </div>
      </v-card-text>

      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-col
              md="4"
              cols="12"
            >
              <v-text-field
                readonly
                v-model="branch"
                label="Branch"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col
              md="4"
              cols="12"
            >
              <v-text-field
                type="date"
                v-model="date_accomplished"
                label="Date Accomplished"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
            <v-col
              md="4"
              cols="12"
            >
              <v-text-field
                v-model="cmf_no"
                label="CMF No."
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-card
              flat
            >
              <v-toolbar dense dark color="primary">
                <v-toolbar-title><h4 class="font-weight-light">PERSONAL INFOMATION</h4>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-col
                  md="4"
                  cols="12"
                ></v-col>
                <v-row>
                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="last_name"
                      label="Last Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="first_name"
                      label="First Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="middle_name"
                      label="Middle Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="date_of_birth"
                      label="Date of Birth"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                      type="date"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="place_of_birth"
                      dense
                      label="Place of Birth"
                      outlined
                      :rules="rules.default_max_255_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-select
                      v-model="gender"
                      dense
                      outlined
                      label="Gender"
                      :items="['Male','Female']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="tin_no"
                      dense
                      outlined
                      label="Tax ID Number (TIN)"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="sss_no"
                      dense
                      outlined
                      label="SSS Number"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="religion"
                      dense
                      outlined
                      label="Religion"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-select
                      v-model="civil_status"
                      dense
                      outlined
                      label="Civil Status"
                      :items="['Single','Married','Widowed','Separated']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="no_of_dependents"
                      dense
                      outlined
                      label="No. Of Dependents"
                      type="number"
                      min="0"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-select
                      v-model="educational_attainment"
                      dense
                      outlined
                      label="Educational Attainment"
                      :items="['Elementary Level','Secondary Level','College Level','College Graduate']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="present_address"
                      dense
                      outlined
                      label="Present Address"
                      :rules="rules.default_max_255_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-select
                      v-model="residence_is"
                      dense
                      outlined
                      label="Residence is"
                      :items="['Owned','Rented','Mortgaged','Others']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-if="residence_is=='Others'"
                      v-model="residence_specify"
                      dense
                      outlined
                      label="Specify (if others)"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="10"
                  >
                    <v-text-field
                      v-model="permanent_address"
                      dense
                      outlined
                      label="Permanent Address"
                      :rules="rules.default_max_255_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-text-field
                      v-model="zip_code"
                      dense
                      outlined
                      label="Zip Code"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="citizenship"
                      dense
                      outlined
                      label="Citizenship"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="contact_no"
                      dense
                      outlined
                      label="Contact Number/s"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="email_address"
                      dense
                      outlined
                      label="Email Address"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card
              flat
            >
              <v-toolbar dense dark color="primary">
                <v-toolbar-title><h4 class="font-weight-light">SPOUSE'S INFORMATION/FAMILY
                  REPRESENTATIVE</h4>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-col
                  md="4"
                  cols="12"
                ></v-col>
                <v-row>
                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="family_last_name"
                      label="Last Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="family_first_name"
                      label="First Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="family_middle_name"
                      label="Middle Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="family_date_of_birth"
                      label="Date of Birth"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                      type="date"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="family_contact_no"
                      dense
                      label="Contact Number/s"
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="family_occupation"
                      dense
                      label="Occupation"
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="family_employer"
                      dense
                      outlined
                      label="Employer/Business"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="8"
                  >
                    <v-text-field
                      v-model="family_employer_address"
                      dense
                      outlined
                      label="Business/Employer's Address/Contact Number"
                      :rules="rules.default_max_255_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="family_monthly_income"
                      dense
                      outlined
                      label="Monthly Income (in peso)"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="family_employment_status"
                      dense
                      outlined
                      label="Employment Status"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="family_employment_date"
                      dense
                      outlined
                      label="Length of Service/Date Started"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card
              flat
            >
              <v-toolbar dense dark color="primary">
                <v-toolbar-title><h4 class="font-weight-light">EMPLOYMENT/BUSINESS INFORMATION</h4>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-col
                  md="4"
                  cols="12"
                ></v-col>
                <v-row>
                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="occupation"
                      label="Occupation"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="employment_date"
                      label="Length of Service/Date Started"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="employer"
                      label="Employer/Business Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="8"
                  >
                    <v-text-field
                      v-model="employer_address"
                      label="Employer/Business Address"
                      dense
                      outlined
                      :rules="rules.default_max_255_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="employer_contact_no"
                      dense
                      label="Contact Number/s"
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="8"
                  >
                    <v-select
                      v-model="monthly_income"
                      dense
                      outlined
                      label="Which of the following comes closest to your monthly income in pesos"
                      :items="['Under 8,000','8,000-15,000','15,001-30,000','30,001-50,000','50,001-100,000','Above 100,000']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-select
                      v-model="employment_status"
                      dense
                      outlined
                      label="Employment Status"
                      :items="['Regular/Permanent','Contractual','Self Employed','Others']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-col
                  md="4"
                  cols="12"
                ></v-col>
                <v-card
                  flat
                >
                  <v-toolbar dense dark>
                    <v-toolbar-title><h6 class="font-weight-light">REFERENCES</h6>
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-col
                      md="4"
                      cols="12"
                    ></v-col>
                    <v-row>
                      <v-col
                        md="3"
                        cols="12"
                      >
                        <v-text-field
                          v-model="ref_name_1"
                          label="Name"
                          dense
                          outlined
                          :rules="rules.default_max_45_character_rule"
                        ></v-text-field>
                      </v-col>

                      <v-col
                        md="3"
                        cols="12"
                      >
                        <v-text-field
                          v-model="ref_address_1"
                          label="Address"
                          dense
                          outlined
                          :rules="rules.default_max_255_character_rule"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        md="3"
                        cols="12"
                      >
                        <v-text-field
                          v-model="ref_contact_no_1"
                          label="Telephone No."
                          dense
                          outlined
                          :rules="rules.default_max_45_character_rule"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        md="3"
                      >
                        <v-text-field
                          v-model="ref_relation_1"
                          label="Relation"
                          dense
                          outlined
                          :rules="rules.default_max_45_character_rule"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        md="3"
                        cols="12"
                      >
                        <v-text-field
                          v-model="ref_name_2"
                          label="Name"
                          dense
                          outlined
                          :rules="rules.default_max_45_character_rule"
                        ></v-text-field>
                      </v-col>

                      <v-col
                        md="3"
                        cols="12"
                      >
                        <v-text-field
                          v-model="ref_address_2"
                          label="Address"
                          dense
                          outlined
                          :rules="rules.default_max_255_character_rule"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        md="3"
                        cols="12"
                      >
                        <v-text-field
                          v-model="ref_contact_no_2"
                          label="Telephone No."
                          dense
                          outlined
                          :rules="rules.default_max_45_character_rule"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        md="3"
                      >
                        <v-text-field
                          v-model="ref_relation_2"
                          label="Relation"
                          dense
                          outlined
                          :rules="rules.default_max_45_character_rule"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card
                  flat
                >
                  <v-toolbar dense dark>
                    <v-toolbar-title><h6 class="font-weight-light">TO BE FILLED UP BY THE COOP
                      REPRESENTATIVE</h6>
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-col
                      md="4"
                      cols="12"
                    ></v-col>
                    <v-row>
                      <v-col
                        md="8"
                        cols="12"
                      >
                        <v-select
                          v-model="type_of_client"
                          dense
                          outlined
                          label="Type of Client"
                          :items="['Reffered By','Advertisement (radio, tv, print media)','Events/Trade Fair','Walk In','Internet Search/Social Media','Others']"
                          :rules="rules.combobox_rule"
                        ></v-select>
                      </v-col>

                      <v-col
                        md="4"
                        cols="12"
                      >
                        <v-text-field
                          v-if="type_of_client=='Others'||type_of_client=='Reffered By'"
                          v-model="type_of_client_specify"
                          label="Specify"
                          dense
                          outlined
                          :rules="rules.default_max_45_character_and_no_empty_rule"
                        ></v-text-field>
                      </v-col>
                      <v-checkbox
                        v-model="is_with_signature"
                        label="With Signature"
                        class="mt-0"
                        hide-details
                      ></v-checkbox>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-card-text>
            </v-card>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert
                color="warning"
                text
                class="mb-0"

              >
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{alert_message}}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-btn
                color="primary"
                class="me-3 mt-4"
                @click="save_member"
                v-if="!saving"
              >
                Save changes
              </v-btn>
              <v-progress-circular
                :size=50
                :width="5"
                color="primary"
                indeterminate
                v-else
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>


    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'
  import 'croppie/croppie.css'// import the croppie css manually
  import {mapGetters, mapActions} from "vuex";
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        show: false,
        avatarImg: require('@/assets/images/avatars/1.png'),
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
        },
      }
    },
    created() {
      this.member_counter_data()
    },
    data() {
      return {

        saving: false,
        alert: false,
        alert_message: '',
        croppieImage: '',
        cropped: this.avatarImg,

        date_accomplished: '',
        cmf_no: '',
        last_name: '',
        first_name: '',
        middle_name: '',
        date_of_birth: '',
        place_of_birth: '',
        gender: '',
        tin_no: '',
        sss_no: '',
        religion: '',
        civil_status: '',
        no_of_dependents: '',
        educational_attainment: '',
        present_address: '',
        residence_is: '',
        residence_specify: '',
        permanent_address: '',
        zip_code: '',
        citizenship: '',
        contact_no: '',
        email_address: '',
        family_last_name: '',
        family_first_name: '',
        family_middle_name: '',
        family_date_of_birth: '',
        family_contact_no: '',
        family_occupation: '',
        family_employer: '',
        family_employer_address: '',
        family_monthly_income: '',
        family_employment_status: '',
        family_employment_date: '',
        occupation: '',
        employment_date: '',
        employer: '',
        employer_address: '',
        employer_contact_no: '',
        monthly_income: '',
        employment_status: '',
        ref_name_1: '',
        ref_address_1: '',
        ref_contact_no_1: '',
        ref_relation_1: '',
        ref_name_2: '',
        ref_address_2: '',
        ref_contact_no_2: '',
        ref_relation_2: '',
        type_of_client: '',
        type_of_client_specify: '',
        is_with_signature: true,
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', ['branch', 'branch_id', 'branch_address', 'branch_contact_no','month_start', 'month_end', 'month_of']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('members_information', ['save_new_members_information', 'member_counter', 'is_members_already_encoded']),
      reset() {
        this.saving = false
        this.alert = false
        this.alert_message = ''
        this.croppieImage = ''
        this.cropped = this.avatarImg
        this.date_accomplished = ''
        this.cmf_no = ''
        this.last_name = ''
        this.first_name = ''
        this.middle_name = ''
        this.date_of_birth = ''
        this.place_of_birth = ''
        this.gender = ''
        this.tin_no = ''
        this.sss_no = ''
        this.religion = ''
        this.civil_status = ''
        this.no_of_dependents = ''
        this.educational_attainment = ''
        this.present_address = ''
        this.residence_is = ''
        this.residence_specify = ''
        this.permanent_address = ''
        this.zip_code = ''
        this.citizenship = ''
        this.contact_no = ''
        this.email_address = ''
        this.family_last_name = ''
        this.family_first_name = ''
        this.family_middle_name = ''
        this.family_date_of_birth = ''
        this.family_contact_no = ''
        this.family_occupation = ''
        this.family_employer = ''
        this.family_employer_address = ''
        this.family_monthly_income = ''
        this.family_employment_status = ''
        this.family_employment_date = ''
        this.occupation = ''
        this.employment_date = ''
        this.employer = ''
        this.employer_address = ''
        this.employer_contact_no = ''
        this.monthly_income = ''
        this.employment_status = ''
        this.ref_name_1 = ''
        this.ref_address_1 = ''
        this.ref_contact_no_1 = ''
        this.ref_relation_1 = ''
        this.ref_name_2 = ''
        this.ref_address_2 = ''
        this.ref_contact_no_2 = ''
        this.ref_relation_2 = ''
        this.type_of_client = ''
        this.type_of_client_specify = ''
        this.is_with_signature = true
      },
      croppie(e) {
        this.show = true;
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;

        var reader = new FileReader();
        reader.onload = e => {
          this.cropped = this.croppieImage = e.target.result
        };
        reader.readAsDataURL(files[0]);
      },
      resett() {
        this.$refs.refInputEl.value = ''
        this.cropped = this.avatarImg
      },
      member_counter_data() {
        this.member_counter()
          .then(response => {
            this.cmf_no = moment().format('YYYY') + '-' + (response.data + 1)
          })
          .catch(error => {
            this.saving = false
          })
      },
      save_member() {
        this.saving = true
        this.alert = false
        var flag = false
        if (moment(this.date_accomplished, 'YYYY-MM-DD').format('MMMM D, YYYY') === moment(this.month_start, 'MMMM D, YYYY').format('MMMM D, YYYY')
          || moment(this.date_accomplished, 'YYYY-MM-DD').format('MMMM D, YYYY') === moment(this.month_end, 'MMMM D, YYYY').format('MMMM D, YYYY')) {
          flag = true
        } else {
          var compareDate = moment(this.date_accomplished, 'YYYY-MM-DD')
          var startDate = moment(this.month_start, 'MMMM D, YYYY')
          var endDate = moment(this.month_end, 'MMMM D, YYYY')

          flag = compareDate.isBetween(startDate, endDate) //false in this case
        }
        if (flag === false) {
          this.date_accomplished = ''
          this.alert = true
          this.alert_message = 'Effective Date is Not Belong in Transaction Month!'
          this.saving = false
        } else {
          if (this.$refs.form.validate()) {
            const data = new FormData()
            data.append('last_name', this.last_name.toUpperCase());
            data.append('first_name', this.first_name.toUpperCase());
            data.append('middle_name', this.middle_name.toUpperCase());
            this.is_members_already_encoded(data)
              .then(response => {
                if (response.data === 1) {
                  this.alert = true
                  this.alert_message = 'Member Already Encoded!'
                  this.saving = false
                } else {
                  data.append('profile_picture', this.croppieImage);
                  data.append('branch_id', this.branch_id);
                  data.append('cmf_no', this.cmf_no);
                  data.append('month_of', this.month_of);
                  data.append('date_accomplished', moment(this.date_accomplished, 'YYYY-MM-DD').format('MMMM D, YYYY'));
                  data.append('date_of_birth', moment(this.date_of_birth, 'YYYY-MM-DD').format('MMMM D, YYYY'));
                  data.append('place_of_birth', this.place_of_birth);
                  data.append('gender', this.gender);
                  data.append('tin_no', this.tin_no);
                  data.append('sss_no', this.sss_no);
                  data.append('religion', this.religion);
                  data.append('civil_status', this.civil_status);
                  data.append('no_of_dependents', this.no_of_dependents);
                  data.append('educational_attainment', this.educational_attainment);
                  data.append('present_address', this.present_address);
                  data.append('residence_is', this.residence_is);
                  data.append('residence_specify', this.residence_specify);
                  data.append('permanent_address', this.permanent_address);
                  data.append('zip_code', this.zip_code);
                  data.append('citizenship', this.citizenship);
                  data.append('contact_no', this.contact_no);
                  data.append('email_address', this.email_address);
                  data.append('family_last_name', this.family_last_name.toUpperCase());
                  data.append('family_first_name', this.family_first_name.toUpperCase());
                  data.append('family_middle_name', this.family_middle_name.toUpperCase());
                  data.append('family_date_of_birth', moment(this.family_date_of_birth, 'YYYY-MM-DD').format('MMMM D, YYYY'));
                  data.append('family_contact_no', this.family_contact_no);
                  data.append('family_occupation', this.family_occupation);
                  data.append('family_employer', this.family_employer.toUpperCase());
                  data.append('family_employer_address', this.family_employer_address);
                  data.append('family_monthly_income', this.family_monthly_income);
                  data.append('family_employment_status', this.family_employment_status);
                  data.append('family_employment_date', this.family_employment_date);
                  data.append('occupation', this.occupation);
                  data.append('employment_date', this.employment_date);
                  data.append('employer', this.employer.toUpperCase());
                  data.append('employer_address', this.employer_address);
                  data.append('employer_contact_no', this.employer_contact_no);
                  data.append('monthly_income', this.monthly_income);
                  data.append('employment_status', this.employment_status);
                  data.append('ref_name_1', this.ref_name_1.toUpperCase());
                  data.append('ref_address_1', this.ref_address_1);
                  data.append('ref_contact_no_1', this.ref_contact_no_1);
                  data.append('ref_relation_1', this.ref_relation_1);
                  data.append('ref_name_2', this.ref_name_2.toUpperCase());
                  data.append('ref_address_2', this.ref_address_2);
                  data.append('ref_contact_no_2', this.ref_contact_no_2);
                  data.append('ref_relation_2', this.ref_relation_2);
                  data.append('type_of_client', this.type_of_client);
                  data.append('type_of_client_specify', this.type_of_client_specify.toUpperCase());
                  var with_signature = 0
                  if (this.is_with_signature) {
                    with_signature = 1
                  }
                  data.append('is_with_signature', with_signature);
                  this.save_new_members_information(data)
                    .then(response => {
                      this.print_data()
                      this.change_snackbar({
                        show: true,
                        color: 'success',
                        text: response.data,
                      })
                      this.reset()
                    })
                    .catch(error => {
                      this.alert = true
                      this.alert_message = error
                      console.log(error)
                      this.saving = false
                    })
                }
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving = false
              })
          } else {
            this.alert = true
            this.alert_message = 'Please Fill up the field/s'
            this.saving = false
          }
        }
      },
      print_data() {
        var imgData = this.company_logo
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: {
            width: 612,
            height: 936
          },
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE SAVINGS & CREDIT COOPERATIVE',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'C.D.A. REG. No. 9520-1011000000048747 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'MEMBERSHIP APPLICATION FORM', style: 'title'},
            '================================================================================',
            ' ',
            {
              stack: [
                {
                  columns: [
                    {text: 'Branch', style: 'main_info1'},
                    {text: 'Date Accomplished', style: 'main_info1'},
                    {text: 'CMF #', style: 'main_info1'},
                  ]
                },
                {
                  columns: [
                    {text: this.branch, style: 'main_info'},
                    {
                      text: moment(this.date_accomplished, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                      style: 'main_info'
                    },
                    {text: this.cmf_no, style: 'main_info'},
                  ]
                },
              ]
            },
            ' ',
            'PERSONAL INFORMATION',
            {
              stack: [
                {
                  columns: [
                    {text: 'Last Name', style: 'main_info1'},
                    {text: 'First Name', style: 'main_info1'},
                    {text: 'Middle Name', style: 'main_info1'},
                  ]
                },
                {
                  columns: [
                    {text: this.last_name, style: 'main_info'},
                    {text: this.first_name, style: 'main_info'},
                    {text: this.middle_name, style: 'main_info'},
                  ]
                },
                {
                  columns: [
                    {text: 'Date of Birth', style: 'main_info1'},
                    {text: 'Place of Birth', style: 'main_info1'},
                    {text: 'Gender', style: 'main_info1'},
                  ]
                },
                {
                  columns: [
                    {text: this.date_of_birth, style: 'main_info'},
                    {text: this.place_of_birth, style: 'main_info'},
                    {text: this.gender, style: 'main_info'},
                  ]
                },
                {
                  columns: [
                    {text: 'TIN #', style: 'main_info1'},
                    {text: 'SSS #', style: 'main_info1'},
                    {text: 'Religion', style: 'main_info1'},
                  ]
                },
                {
                  columns: [
                    {text: this.tin_no, style: 'main_info'},
                    {text: this.sss_no, style: 'main_info'},
                    {text: this.religion, style: 'main_info'},
                  ]
                },
                {
                  columns: [
                    {text: 'Civil Status', style: 'main_info1'},
                    {text: 'No. of Dependents', style: 'main_info1'},
                    {text: 'Educational Attainment', style: 'main_info1'},
                  ]
                },
                {
                  columns: [
                    {text: this.civil_status, style: 'main_info'},
                    {text: this.no_of_dependents, style: 'main_info'},
                    {text: this.educational_attainment, style: 'main_info'},
                  ]
                },
                {
                  columns: [
                    {text: 'Present Address', style: 'main_info1'},
                    {text: 'Residence', style: 'main_info1'},
                    {text: 'Others (Specify)', style: 'main_info1'},
                  ]
                },
                {
                  columns: [
                    {text: this.present_address, style: 'main_info'},
                    {text: this.residence_is, style: 'main_info'},
                    {text: this.residence_specify, style: 'main_info'},
                  ]
                },
                {
                  columns: [
                    {text: 'Permanent Address', style: 'main_info1'},
                    {text: 'Zip Code', style: 'main_info1'},
                  ]
                },
                {
                  columns: [
                    {text: this.permanent_address, style: 'main_info'},
                    {text: this.zip_code, style: 'main_info'},
                  ]
                },
                {
                  columns: [
                    {text: 'Citizenship', style: 'main_info1'},
                    {text: 'Contact #', style: 'main_info1'},
                    {text: 'Email Address', style: 'main_info1'},
                  ]
                },
                {
                  columns: [
                    {text: this.citizenship, style: 'main_info'},
                    {text: this.contact_no, style: 'main_info'},
                    {text: this.email_address, style: 'main_info'},
                  ]
                },
              ]
            },
            ' ',
            'SPOUSE INFORMATION / FAMILY REPRESENTATIVE',
            {
              stack: [
                {
                  columns: [
                    {text: 'Last Name', style: 'main_info1'},
                    {text: 'First Name', style: 'main_info1'},
                    {text: 'Middle Name', style: 'main_info1'},
                  ]
                },
                {
                  columns: [
                    {text: this.family_last_name, style: 'main_info'},
                    {text: this.family_first_name, style: 'main_info'},
                    {text: this.family_middle_name, style: 'main_info'},
                  ]
                },
                {
                  columns: [
                    {text: 'Date of Birth', style: 'main_info1'},
                    {text: 'Place of Birth', style: 'main_info1'},
                    {text: 'Occupation', style: 'main_info1'},
                  ]
                },
                {
                  columns: [
                    {text: this.family_date_of_birth, style: 'main_info'},
                    {text: this.family_contact_no, style: 'main_info'},
                    {text: this.family_occupation, style: 'main_info'},
                  ]
                },
                {
                  columns: [
                    {text: 'Employer/Business', style: 'main_info1'},
                    {text: 'Business/Employers Contact #', style: 'main_info1'},
                  ]
                },
                {
                  columns: [
                    {text: this.family_employer, style: 'main_info'},
                    {text: this.family_employer_address, style: 'main_info'},
                  ]
                },
                {
                  columns: [
                    {text: 'Monthly Income (in peso)', style: 'main_info1'},
                    {text: 'Enployment Status', style: 'main_info1'},
                    {text: 'Length of Service/Employment Date', style: 'main_info1'},
                  ]
                },
                {
                  columns: [
                    {text: this.family_monthly_income, style: 'main_info'},
                    {text: this.family_employment_status, style: 'main_info'},
                    {text: this.family_employment_date, style: 'main_info'},
                  ]
                },
              ]
            },
            ' ',
            'EMPLOYMENT/BUSINESS INFORMATION',
            {
              stack: [
                {
                  columns: [
                    {text: 'Occupation', style: 'main_info1'},
                    {text: 'Length of Service/Date Started', style: 'main_info1'},
                    {text: 'Employer/Business', style: 'main_info1'},
                  ]
                },
                {
                  columns: [
                    {text: this.occupation, style: 'main_info'},
                    {text: this.employment_date, style: 'main_info'},
                    {text: this.employer, style: 'main_info'},
                  ]
                },
                {
                  columns: [
                    {text: 'Employer/Business Address', style: 'main_info1'},
                    {text: 'Business/Employers Contact #', style: 'main_info1'},
                  ]
                },
                {
                  columns: [
                    {text: this.employer_address, style: 'main_info'},
                    {text: this.employer_contact_no, style: 'main_info'},
                  ]
                },
                {
                  columns: [
                    {text: 'Monthly Income (in peso)', style: 'main_info1'},
                    {text: 'Enployment Status', style: 'main_info1'},
                  ]
                },
                {
                  columns: [
                    {text: this.monthly_income, style: 'main_info'},
                    {text: this.employment_status, style: 'main_info'},
                  ]
                },
                ' ',
                'REFERENCES',
                {
                  columns: [
                    {text: 'Name', style: 'main_info1'},
                    {text: 'Address', style: 'main_info1'},
                    {text: 'Telephone #', style: 'main_info1'},
                    {text: 'Relation', style: 'main_info1'},
                  ]
                },
                {
                  columns: [
                    {text: this.ref_name_1, style: 'main_info'},
                    {text: this.ref_address_1, style: 'main_info'},
                    {text: this.ref_contact_no_1, style: 'main_info'},
                    {text: this.ref_relation_1, style: 'main_info'},
                  ]
                },
                {
                  columns: [
                    {text: this.ref_name_2, style: 'main_info'},
                    {text: this.ref_address_2, style: 'main_info'},
                    {text: this.ref_contact_no_2, style: 'main_info'},
                    {text: this.ref_relation_2, style: 'main_info'},
                  ]
                },
              ]
            },
            ' ',
            'TO BE FILLED UP BY THE COOP REPRESENTATIVE',
            {
              stack: [
                {
                  columns: [
                    {text: 'Type of Client', style: 'main_info1'},
                    {text: 'Others (Specify)', style: 'main_info1'},
                  ]
                },
                {
                  columns: [
                    {text: this.type_of_client, style: 'main_info'},
                    {text: this.type_of_client_specify, style: 'main_info'},
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  columns: [
                    {
                      text: this.last_name + ', ' + this.first_name + ' ' + this.middle_name,
                      style: 'aa_for'
                    },
                    {text: '', style: 'cashier_for'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'aa_line'},
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: '_________________________________', style: 'cashier_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'Signature of Applicant', style: 'aa_placeholder'},
                    {text: 'Coop Representative', style: 'cashier_placeholder'},
                    {text: 'Branch Manager/General Manager', style: 'cashier_placeholder'},
                  ]
                },
              ]
            },

          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Savings & Credit Cooperative System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info1: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 9,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 9,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    }
  }
</script>
